/* eslint-disable */
$(function () {
    var $currentPageElement = $('.js-content-navigation .js-current-page');
    var formatter = new Intl.NumberFormat('en-US', { style: 'percent' });
    function isInViewport($this) {
        var $window = $(window);
        var elementBottom = $this.offset().top + $this.outerHeight();
        var viewportBottom = $window.scrollTop() + $window.height();
        return elementBottom > $window.scrollTop()
            && $this.offset().top < viewportBottom;
    }

    function checkAcademyItem($this) {
        $(window).off('resize scroll', scrollCheck);
        var $containerParent = $this.parents('.js-content-navigation');
        var formData = {
            academyPageId: $this.data('pageid'),
            blockContentGuid: $containerParent.data('blockcontentguid'),
            add: !$this.prop('checked') ? false : $this.prop('checked')
        };
        $.ajax({
            type: 'POST',
            url: '/academy/progress/set',
            data: formData,
            success: function (data) {
                var percentageCompleted = parseInt(data, 10);
                var $progress = $containerParent.find('.js-progress');
                var $label = $containerParent.find('.js-progress-label');
                var $parentBox = $this.parents('.js-academy-item');
                $progress.attr('value', percentageCompleted);
                $label.html(formatter.format(percentageCompleted / 100));
                if (formData.add) {
                    $parentBox.addClass('is-completed');
                }
                else {
                    $parentBox.removeClass('is-completed');
                }
                loadProgress();
            }
        });
    }

    function scrollCheck() {
        if (isInViewport($currentPageElement)) {
            var $checkbox = $currentPageElement.find('input[type="checkbox"]');
            $checkbox.prop('checked', true);
            checkAcademyItem($checkbox);
        }
    }

    function loadProgress() {
        var $containerParent = $('.js-content-navigation');
        var ticks = (new Date()).getTime();
        $.ajax({
            type: 'POST',
            url: '/academy/progress/display',
            data: {
                ticks: ticks,
                blockContentGuid: $containerParent.data('blockcontentguid')
            },
            success: function (data) {
                var percentageCompleted = data.academyProgress;
                var $progress = $containerParent.find('.js-progress');
                var $label = $containerParent.find('.js-progress-label');
                $progress.attr('value', percentageCompleted);
                $label.html(formatter.format(percentageCompleted / 100));
                for (var i = 0; i < data.completed.length; i++) {
                    var pageId = data.completed[i];
                    var $checkbox = $('#input-' + pageId.toString());
                    $checkbox.attr('checked', 'checked');
                    var $parentBox = $checkbox.parents('.js-academy-item');
                    $parentBox.addClass('is-completed');
                }
            }
        }
        );
    }

    // hooks for clicking the checkbox and scrolling on the condensed page
    $('.js-content-navigation input[type="checkbox"]').click(function (e) {
        checkAcademyItem($(this));
    });

    if ($currentPageElement.length > 0
        && !$currentPageElement.find('input[type="checkbox"]').prop('checked')) {
        $(window).on('resize scroll', scrollCheck);
    }

    // Load only if it is an academy pages.
    if (isAcademyProgressEnabled) {
        loadProgress();
    }
});
